import { fetchRuns, updateCheckListItem } from "redux/Authentication/thunks"; // ,
import { resetWorkWeek } from "redux/WorkWeek/actions";
import { receiveResourcePlan } from "redux/Resource/actions";
import { fetchResourcePlan } from "redux/Resource/thunks";
import { fetchChartData, fetchLaborSummaryData, fetchHistoryData } from "redux/Report/thunks";
import { fetchTraining } from "redux/Training/thunks";
import { fetchRisks } from "redux/Risk/thunks";
import { fetchStakeholderPlan } from "redux/Stakeholder/thunks";
import { firstRunCheckBool } from "redux/Authentication/selectors";
import { processFailToFetch } from "utilities/handleFetchErrors";
import {
  addSingleAlert,
  dismissAlertById,
  createAPIError
} from "redux/Notification/NotificationModule";
import history from "../../history";
import {
  setRunId,
  toggleWeeklyStaffingAccepted,
  budgetReceived,
  actionError,
  updateBudgetSuccess,
  setBudgetIsLocked,
  communicationRead,
  projectResumed,
  setProjectAsReadOnly,
  bumpRunSuccess,
  requestingData,
  setNewProjectStarted,
  receiveAssignments,
  processAssignmentsSuccess,
  acceptWeeklyStaffingSuccess,
  receiveProjectData,
  clearProject,
  checkListUpdated
} from "./actions";
import { historyReceived } from "redux/Report/actions"
import { toggleLoading } from "app/redux/actions";
import * as actions from "./actions";

export const setNewRunId = (payload) => {
  const runId = payload.StudentRunDetailObjDTO.RunId;
  return async (dispatch) => {
    await dispatch(setRunId(runId));
  };
};

export const clearRunId = () => async (dispatch) => {
  await dispatch(setRunId(0));
};

export const toggleStaffing = (payload) => async (dispatch) => {
  await dispatch(toggleWeeklyStaffingAccepted(payload));
};

export const updateProjectCheckListItem = (clId, clNo, clVal) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      id: clId,
      controlNumber: clNo,
      runId: getState().project.runId,
      value: clVal
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/updateChecklist`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error updating checklist project item",
        message: `id: ${clId}  updateProjectChecklistItem : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `id: ${clId}  updateProjectChecklistItem : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          clId,
          message: "Entity error while creating updateProjectChecklistItem api"
        })
      );
      return false;
    }
    if (json.status !== "error") {
      dispatch(
        checkListUpdated(JSON.parse(json.payload))
      );
      return false;
    }
    dispatch(actionError(json.statusMessage));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "updateProjectChecklistItem"
    );
    return false;
  }
};

export const recomputeResourcePlan = (runId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(toggleLoading());
    const payload = {
      runId,
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/recomputeLaborBudget`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;

    // wait for json respone to come back
    const json = await response.json();
    dispatch(actions.budgetReceived(JSON.parse(json.payload)));
    return true;
  } catch (e) {
    dispatch(toggleLoading());
    throw e
  }
};

export const requestUpdatedBudget = (runId) => async (dispatch, getState) => {
  try {
    const payload = {
      runId
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/getBudget`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error accessing the getBudget api",
        message: `runId: ${runId}  fetch getBudget : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  getBudget : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    dispatch(budgetReceived(JSON.parse(json.payload)));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "requestUpdatedBudget"
    );
    return false;
  }
};
// used for recognition updates only
export const updateBudget = (budgetObj) => async (dispatch, getState) => {
  try {
    // removing the ability to pass '' to api call which cannot deserialize '' in this case
    const checkBudgetObj = { ...budgetObj };

    let budgetAmt = 0;
    if (!Number.isNaN(Number.parseFloat(checkBudgetObj.BudgetAmt))) {
      budgetAmt = Number.parseFloat(checkBudgetObj.BudgetAmt);
    } // if string, changes to 0
    checkBudgetObj.BudgetAmt = budgetAmt;

    const payload = {
      budgetObj: checkBudgetObj
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/saveBudget`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `update budget result : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `update budget result : http request response error: ${json.statusMessage}`
      );
    }
    await dispatch(
      budgetReceived(JSON.parse(json.payload))
    );
    return true;
  } catch (e) {
    throw e
  }
};

export const lockBudget = (runId) => async (dispatch, getState) => {
  try {
    const payload = {
      runId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonPlanningSvc.svc/lockBudget`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();

    if (!response.ok) {
      throw new Error(
        `lockTheBudgetResult : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `lockTheBudgetResult : http request response error: ${json.statusMessage}`
      );
    }

    await (dispatch(requestUpdatedBudget(runId)),
      dispatch(acceptWeeklyStaffingPlan(runId, 1)),
      dispatch(setBudgetIsLocked()),
      dispatch(fetchChartData(runId)))

    return true;
  } catch (e) {
    throw e;
  }
};

export const markAsRead = (id) => async (dispatch, getState) => {
  try {
    const { runId } = getState().project;

    const payload = {
      id
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/markAsRead`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error accessing the getBudget api",
        message: `runId: ${runId}  fetch getBudget : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  getBudget : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      // We can dispatch many times!
      // Here, we update the app state with the results of the API call.
      dispatch(actionError(json.statusMessage));
      return false;
    }
    dispatch(
      communicationRead(JSON.parse(json.payload))
    );
    return true;
  } catch (e) {
    processFailToFetch(e, getState(), dispatch, addSingleAlert, "markAsRead");
    return false;
  }
};

export const resumeProject = (runId, productId) => async (
  dispatch,
  getState
) => {
  try {
    if (runId === 0 || productId === 0) {
      return false;
    }
    const payload = {
      runId,
      productId: getState().auth.productId
    };
    dispatch(setProjectAsReadOnly(false));

    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/resumeProject`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      throw new Error(
        `resumeProjectResult : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `resumeProjectResult : http request response error: ${json.statusMessage}`
      );
    }

    const pl = JSON.parse(json.payload);
    dispatch(projectResumed(pl));
    dispatch(historyReceived(pl.projectDataLists.projectHistoryList))

    await dispatch(fetchResourcePlan(runId))
    await dispatch(fetchRuns(getState().auth.userId)); // make sure we have the latest classroom run flags & stopweeks
    dispatch(fetchChartData(runId));
    dispatch(fetchLaborSummaryData(runId));
    dispatch(resetWorkWeek()); // sometimes the button won't show


    // move user to executions phase by default if planning is done
    if (getState().project.budgetIsLocked === true) {
      // need to check if the project has been interrupted mid runweek
      // look for the ENDREPORT for the current week in the history report
      // if it doesn't exist, redirect the users
      //
      if (
        getState().project.projectStatus.WeekHasCompleted === false
      ) {
        history.push("/execution/workweek");
      } else {
        history.push("/execution");
      }
    } else {
      history.push("/planning");
    }

    dispatch(dismissAlertById(1));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "resumeProject"
    );
    return false;
  }
};

export const reviewProject = (runId, productId) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId,
      productId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/resumeProject`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error in reviewProject accessing the resumeProject api",
        message: `runId: ${runId}  fetch reviewProject/resumeProject : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  reviewProject / resumeProject : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating reviewProject/resumeProject api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    const pl = JSON.parse(json.payload);
    await dispatch(resetWorkWeek()); // sometimes the button won't show

    await dispatch(projectResumed(pl));
    Promise.all([
      dispatch(setProjectAsReadOnly(true)),
      dispatch(fetchResourcePlan(runId)), // because this one is used so much, need it now
      dispatch(fetchStakeholderPlan(runId)),
      dispatch(fetchTraining(runId)),
      dispatch(fetchRisks(runId)),
      dispatch(fetchRuns(getState().auth.userId)), // make sure we have the latest classroom run flags & stopweeks
      dispatch(fetchChartData(runId)),
      dispatch(historyReceived(pl.projectDataLists.projectHistoryList)),
      dispatch(fetchLaborSummaryData(runId)),


    ]).then(() => history.push("/reports/history"));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "reviewProject"
    );
    return false;
  }
};

export const bumpProject = (runId, classStudentRunId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(resetWorkWeek());

    const payload = {
      runId,
      classStudentRunId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/bumpProject`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );

    // api call http status
    // const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    const pl = JSON.parse(json.payload);

    dispatch(bumpRunSuccess(pl));
    dispatch(resetWorkWeek()); // sometimes the button won't show
    await dispatch(fetchRuns(getState().auth.userId));
    return true;
  } catch (e) {
    processFailToFetch(e, getState(), dispatch, addSingleAlert, "bumpRun");
    return false;
  }
};

export const resetProject = (runId, weekNumber) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId,
      productId: getState().auth.productId,
      weekNumber
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/resetProject`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    // const { status } = response;
    // TODO add status checker here for http return status
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }

    const pl = JSON.parse(json.payload);
    dispatch(resetWorkWeek()); // sometimes the button won't show
    dispatch(setProjectAsReadOnly(false));
    dispatch(projectResumed(pl));
    dispatch(historyReceived(pl.projectDataLists.projectHistoryList))
    await dispatch(fetchResourcePlan(runId)); // because this one is used so much, need it now
    dispatch(fetchLaborSummaryData(runId));
    dispatch(fetchChartData(runId));
    dispatch(dismissAlertById(1));

    if (getState().auth.studentInfo.ClassroomStatus === "Registration") {
      history.push("/projects");
    } else {
      // move user to executions phase by default if planning is done
      if (getState().project.budgetIsLocked === true) {
        // need to check if the project has been interrupted mid runweek
        //
        if (getState().project.projectStatus.WeekHasCompleted === false) {
          history.push("/execution/workweek");
        } else {
          history.push("/execution");
        }
      } else {
        history.push("/planning");
      }
    }
    return true;
  } catch (e) {
    throw e;
  }
};

export const instructorClearProject = () => async (dispatch) => {
  dispatch(clearProject());
  return true;
};

export const instructorLoadProject = (runId, productId) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId,
      productId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/resumeProject`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error in reviewProject accessing the resumeProject api",
        message: `runId: ${runId}  fetch instructorLoadProject : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  instructorLoadProject : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating instructorLoadProject api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    const pl = JSON.parse(json.payload);
    await dispatch(projectResumed(pl));
    Promise.all([
      // dispatch(setProjectAsReadOnly(true)),
      dispatch(fetchResourcePlan(runId)), // because this one is used so much, need it now
      dispatch(fetchStakeholderPlan(runId)),
      dispatch(fetchTraining(runId)),
      dispatch(fetchRisks(runId))
    ]);

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "instructorLoadProject"
    );
    return false;
  }
};

export const bumpRun = (userId) => async (dispatch, getState) => {
  const { runId } = getState().project;
  try {
    const payload = {
      userId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonUserSvc.svc/bumpRun`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error in reviewProject accessing the bumpRun api",
        message: `runId: ${runId}  fetch bumpRun : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  bumpRun : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating bumpRun api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    const pl = JSON.parse(json.payload);
    dispatch(bumpRunSuccess(pl));
    dispatch(fetchRuns(getState().auth.userId));
    return true;
  } catch (e) {
    processFailToFetch(e, getState(), dispatch, addSingleAlert, "bumpRun");
    return false;
  }
};

export const startProject = (studentData, planId, planDescription) => async (
  dispatch,
  getState
) => {
  dispatch(requestingData());
  dispatch(setProjectAsReadOnly(false));
  /* eslint-disable no-useless-escape */
  // had to do this because the vb.net api expects dates like this in json
  // end date could be null, not sure why the start date couldn't be null
  const updStudentData = { ...studentData };
  updStudentData.RunStartDate = "/Date(2001-01-01T00:00:00)/";
  /* eslint-enable */
  const payload = {
    studentRunDetail: updStudentData,
    planId,
    planDescription
  };
  const response = await fetch(
    `${getState().app.baseURL}JsonProjectSvc.svc/startProject`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8"
      },
      method: "post",
      body: JSON.stringify(payload)
    }
  );
  // api call http status
  const { status } = response;
  // wait for json respone to come back
  const json = await response.json();
  if (!response.ok) {
    throw new Error(`startProject : http request response error: ${status}`);
  } else if (json.status === "error") {
    throw new Error(
      `startProject : http request response error: ${json.statusMessage}`
    );
  }
  const pl = JSON.parse(json.payload);
  dispatch(resumeProject(pl.runId, pl.productId));

  // dispatch(resumeProject(ri,studentData.ProductId))
  if (firstRunCheckBool(getState()) === false) {
    dispatch(setNewProjectStarted(true));
    dispatch(
      updateCheckListItem(
        getState().auth.userId,
        6,
        "START_FIRST_PROJECT",
        true
      )
    );
  }
  return true;
};

export function fetchAssignments(runId) {
  // Thunk middleware knows how to handle functions.
  // It passes the dispatch method as an argument to the function,
  // thus making it able to dispatch actions itself.
  return async (dispatch, getState) => {
    try {
      const payload = { runId };

      const response = await fetch(
        `${getState().app.baseURL}JsonProjectSvc.svc/assignmentStaffingList`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
          },
          method: "post",
          body: JSON.stringify(payload)
        }
      );
      // api call http status
      const { status } = response;
      // wait for json respone to come back
      const json = await response.json();
      if (!response.ok) {
        // status looks bad
        const alert = {
          id: 20,
          type: "danger",
          event: "Project Error",
          headline: "Error  accessing the assignmentStaffingList api",
          message: `runId: ${runId}  fetch assignmentStaffingList : response status : ${status.toString()}`
        };
        const notificationData = {
          worker: "thunk",
          week: 0,
          info: `runId: ${runId}  fetch assignmentStaffingList : response status : ${status.toString()}`
        };
        dispatch(addSingleAlert(alert, notificationData, 5000, true));
        dispatch(actionError(alert));
        dispatch(
          createAPIError({
            type: "CREATE_API_ENTITY_ERROR",
            errorType: "warning",
            runId,
            message: "Entity error while creating assignmentStaffingList api"
          })
        );
        return false;
      }
      if (json.status === "error") {
        dispatch(
          actionError(json.statusMessage)
        );
        return false;
      }
      dispatch(
        receiveAssignments(
          JSON.parse(json.payload)
        )
      );
      return true;
    } catch (e) {
      processFailToFetch(
        e,
        getState(),
        dispatch,
        addSingleAlert,
        "fetchAssignments"
      );
      return false;
    }
  };
}

export const processAssignments = (
  data,
  updateItem,
  strValue,
  intValue
) => async (dispatch, getState) => {
  try {
    const payload = {
      staffingObj: data,
      updateItem,
      newStringValue: strValue,
      newIntegerValue: intValue
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/processAssignments`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    // status looks bad  processAssignments  processAssignmentsResult

    if (!response.ok) {
      throw new Error(
        `processAssignmentsResult : http request response error: ${status}`
      );
    } else if (json.status === "error") {
      throw new Error(
        `processAssignmentsResult : http request response error: ${json.statusMessage}`
      );
    }
    const jsonPayload = JSON.parse(json.payload);

    await Promise.all([
      dispatch(processAssignmentsSuccess(jsonPayload.assignments)),
      dispatch(receiveResourcePlan(jsonPayload.resourcePlan))
    ]);

    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "processAssignments"
    );
    return false;
  }
};

export const acceptWeeklyStaffingPlan = (runId, weekNumber) => async (
  dispatch,
  getState
) => {
  try {
    const payload = {
      runId,
      weekNumber
    };

    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/acceptWeeklyStaffing`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error  accessing the acceptWeeklyStaffing api",
        message: `runId: ${runId}  fetch acceptWeeklyStaffing : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  fetch acceptWeeklyStaffing : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating acceptWeeklyStaffing api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    const pl = JSON.parse(json.payload);
    dispatch(acceptWeeklyStaffingSuccess(pl));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "acceptWeeklyStaffing"
    );
    return false;
  }
};

export const fetchProjectData = (runId) => async (dispatch, getState) => {
  try {
    const payload = {
      runId
    };
    const response = await fetch(
      `${getState().app.baseURL}JsonProjectSvc.svc/projectData`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8"
        },
        method: "post",
        body: JSON.stringify(payload)
      }
    );
    // (baseURL + 'JsonProjectSvc.svc/projectHashData/' + runId)
    // api call http status
    const { status } = response;
    // wait for json respone to come back
    const json = await response.json();
    if (!response.ok) {
      // status looks bad
      const alert = {
        id: 20,
        type: "danger",
        event: "Project Error",
        headline: "Error  accessing the projectData api",
        message: `runId: ${runId}  fetch projectData : response status : ${status.toString()}`
      };
      const notificationData = {
        worker: "thunk",
        week: 0,
        info: `runId: ${runId}  fetch projectData : response status : ${status.toString()}`
      };
      dispatch(addSingleAlert(alert, notificationData, 5000, true));
      dispatch(actionError(alert));
      dispatch(
        createAPIError({
          type: "CREATE_API_ENTITY_ERROR",
          errorType: "warning",
          runId,
          message: "Entity error while creating projectData api"
        })
      );
      return false;
    }
    if (json.status === "error") {
      dispatch(actionError(json.statusMessage));
      return false;
    }
    dispatch(receiveProjectData(JSON.parse(json.payload)));
    return true;
  } catch (e) {
    processFailToFetch(
      e,
      getState(),
      dispatch,
      addSingleAlert,
      "fetchProjectData"
    );
    return false;
  }
};
