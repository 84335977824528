/* eslint-disable no-unused-vars  */

import { createSelector } from "reselect";

const getProjectDefects = (state) => state.report.projectDefects;
const getDefects = (state) => state.report.defects;
const getSoftwareDefects = (state) => state.report.softwareDefects;
const getHardwareDefects = (state) => state.report.hardwareDefects;
const getACCData = (state) => state.report.ACCData;
const getEACData = (state) => state.report.EACData;
const getEBCData = (state) => state.report.EBCData;
const getEPFData = (state) => state.report.EPFData;
const getEVData = (state) => state.report.EVData;
const getPPIData = (state) => state.report.PPIData;
const getProjectedFinishWeek = (state) => state.project.projectedFinishWeek;
const getWorkerTaskHoursData = (state) => state.report.workerTaskHoursData;
const getProjectWeek = (state) => state.project.currentProjectWeek;
const getHistory = (state) => state.report.history;
const getProductId = (state) => state.auth.productId;


export const getTotalCostByWorker = createSelector(
  [getWorkerTaskHoursData],
  (workerTaskHoursData) => {
    const helper = {};
    const result = workerTaskHoursData.reduce((r, o) => {
      const key = `${o.workerId}`;

      if (!helper[key]) {
        helper[key] = {
          workerId: o.workerId,
          workerName: o.workerName,
          hourlyRate: o.hourlyRate,
          reg: o.accumulatedRegularHours,
          ot: o.accumulatedOvertimeHours,
          total:o.accumulatedRegularHours * o.hourlyRate
        };
        r.push(helper[key]);
      } else {
        helper[key].reg += o.accumulatedRegularHours;
        helper[key].total +=
          o.accumulatedRegularHours  * o.hourlyRate;
        helper[key].ot += o.accumulatedOvertimeHours;
      }

      return r;
    }, []);
    return result;
  }
);

export const getTotalCostByTask = createSelector(
  [getWorkerTaskHoursData],
  (workerTaskHoursData) => {
    const helper = {};
    const result = workerTaskHoursData.reduce((r, o) => {
      const key = `${o.taskId}`;

      if (!helper[key]) {
        helper[key] = {
          task: o.taskId,
          taskName: o.taskName,
          total: o.accumulatedRegularHours * o.hourlyRate,
          reg: o.accumulatedRegularHours
        };

        r.push(helper[key]);
      } else {
        helper[key].reg += o.accumulatedRegularHours;
        helper[key].total += o.accumulatedRegularHours * o.hourlyRate;
      }

      return r;
    }, []);
    /* eslint-disable no-nested-ternary */
    return result.sort((a, b) =>
      a.task > b.task ? 1 : b.task > a.task ? -1 : 0
    );
  }
);

export const checkHasWeekFinished = createSelector(
  [getHistory, getProjectWeek],
  (history, projectWeek) => {
    if (history === null || history === undefined || history === []) {
      return true;
    }

    const currentHistory = history[projectWeek - 1];
    if (
      currentHistory !== null &&
      currentHistory !== undefined &&
      Object.prototype.hasOwnProperty.call(currentHistory, "ComponentList")
    ) {
      const componentList = currentHistory.ComponentList;
      const compCount = componentList.length;
      const endrep = componentList.find((c) => c.ComponentName === "endreport");

      if (endrep !== undefined && endrep !== null) {
        return true;
      }
    } else {
      return false;
    }

    return true;
  }
);

const getLabels = (projectedFinishWeek) => {
  const labels = [];
  const dData = [];
  //labels
  labels[0] = "";
  for (let i = 1; i <= (projectedFinishWeek + 1) * 5; i += 1) {
    if (i % 5 === 0) {
    labels[i] = `Week ${Math.round(i / 5)}`;
    } else {
      labels[i] = "";
    }
  }
  return labels
}
export const getQualityReport = createSelector(
  [
    getDefects,
    getSoftwareDefects,
    getHardwareDefects,
    getProjectDefects,
    getProjectedFinishWeek,
    getProductId
  ],
  (defects, sw, hw, proj, projectedFinishWeek, productId) => {
    const labels = getLabels(projectedFinishWeek);
    const dData = [];

    for (const df in defects) {
      const datapoint = defects[df];
      dData[datapoint.XVAL] = datapoint.YVAL;
    }
    const sData = [];
    for (const ds in sw) {
      const datapoint = sw[ds];
      sData[datapoint.XVAL] = datapoint.YVAL;
    }

    const hData = [];
    for (const dh in hw) {
      const datapoint = hw[dh];
      hData[datapoint.XVAL] = datapoint.YVAL;
    }

    const pData = [];
    for (const dp in proj) {
      const datapoint = proj[dp];
      pData[datapoint.XVAL] = datapoint.YVAL;
    }
    const markerlineColor = productId === 51 ?  "#A888B5": "#FF3364";
    const data = {
      labels,
      bezierCurveTension: 0.4,
      datasets: [
        {
          label: "Target",
          fill: false,
          strokeColor: "#7D33FF",
          backgroundColor: "#7D33FF",
          borderWidth: 1,
          borderColor: "#7D33FF",
          pointColor: "#7D33FF",
          pointStrokeColor: "#7D33FF",
          pointHighlightFill: "#7D33FF",
          pointHighlightStroke: "#7D33FF",
          data: dData
        },
        {
          label: "Software",
          fill: false,
          strokeColor: markerlineColor,
          backgroundColor: markerlineColor,
          borderWidth: 1,
          borderColor: markerlineColor,
          pointColor: markerlineColor,
          pointStrokeColor: markerlineColor,
          pointHighlightFill: markerlineColor,
          pointHighlightStroke: markerlineColor,
          data: sData
        },
        {
          label: "Hardware",
          fill: false,
          strokeColor: "#41F35F",
          backgroundColor: "#41F35F",
          borderWidth: 1,
          borderColor: "#41F35F",
          pointColor: "#41F35F",
          pointStrokeColor: "#41F35F",
          pointHighlightFill: "#41F35F",
          pointHighlightStroke: "#41F35F",
          data: hData
        },
        {
          label: "Project",
          fill: false,
          strokeColor: "#41D5F3",
          backgroundColor: "#41D5F3",
          borderWidth: 1,
          borderColor: "#41D5F3",
          pointColor: "#41D5F3",
          pointStrokeColor: "#41D5F3",
          pointHighlightFill: "#41D5F3",
          pointHighlightStroke: "#41D5F3",
          data: pData
        }
      ]
    };

    return data;
  }
);

export const getEACReport = createSelector(
  [getEACData, getProjectedFinishWeek],
  (eac, projectedFinishWeek) => {

    const eData = [];

    for (const e in eac) {
      const datapoint = eac[e];
      eData[datapoint.XVAL] = datapoint.YVAL;
    }

    const data = {
      labels: getLabels(projectedFinishWeek),
      bezierCurveTension: 0.4,
      datasets: [
        {
          label: "EAC",
          fill: false,
          strokeColor: "#7D33FF",
          backgroundColor: "#7D33FF",
          borderWidth: 1,
          borderColor: "#7D33FF",
          pointColor: "#7D33FF",
          pointStrokeColor: "#7D33FF",
          pointHighlightFill: "#7D33FF",
          pointHighlightStroke: "#7D33FF",
          data: eData
        }
      ]
    };

    return data;
  }
);

export const getEBCReport = createSelector([getEBCData, getProjectedFinishWeek], (ebc, projectedFinishWeek) => {
  const datasets = [];

  const eData = [];
  // for (const e in ebc) {
  //   if (ebc[e].XVAL % 5 === 0) {
  //     labels[e] = String(ebc[e].XVAL); // do labels here
  //   }
  //   eData[e] = ebc[e].YVAL;
  // }
  const data = {
    labels: getLabels(projectedFinishWeek),
    bezierCurveTension: 0.4,
    datasets: [
      {
        label: "Target",
        fill: false,
        strokeColor: "#7D33FF",
        backgroundColor: "#7D33FF",
        borderWidth: 1,
        borderColor: "#7D33FF",
        pointColor: "#7D33FF",
        pointStrokeColor: "#7D33FF",
        pointHighlightFill: "#7D33FF",
        pointHighlightStroke: "#7D33FF",
        data: eData
      }
    ]
  };

  return data;
});

export const getEPFReport = createSelector(
  [getEPFData, getProjectedFinishWeek],
  (epf, projectedFinishWeek) => {
    const datasets = [];
    const labels = [];
    const eData = [];
    for (let i = 0; i <= (projectedFinishWeek + 1) * 5; i += 1) {
      labels[i] = `Week ${Math.round(i / 5)}`;
    }
    for (const e in epf) {
      const datapoint = epf[e];

      eData[datapoint.XVAL] = datapoint.YVAL;
    }

    const data = {
      labels: getLabels(projectedFinishWeek),
      bezierCurveTension: 0.4,
      datasets: [
        {
          label: "Estimated Project Finish",
          fill: false,
          strokeColor: "#7D33FF",
          backgroundColor: "#7D33FF",
          borderWidth: 1,
          borderColor: "#7D33FF",
          pointColor: "#7D33FF",
          pointStrokeColor: "#7D33FF",
          pointHighlightFill: "#7D33FF",
          pointHighlightStroke: "#7D33FF",
          data: eData
        }
      ]
    };

    return data;
  }
);
export const getEVReport = createSelector(
  [getEVData, getEBCData, getACCData, getProjectedFinishWeek, getProductId],
  (ev, ebc, acc, projectedFinishWeek, productId) => {
    const labels = [];
    const eData = [];
    const aData = [];
    const cData = [];
    // labels

    for (let i = 0; i <= (projectedFinishWeek + 1) * 5; i += 1) {
      labels[i] = `Week ${Math.round(i / 5)}`;
    }
    for (const e in ev) {
      const datapoint = ev[e];
      eData[datapoint.XVAL] = datapoint.YVAL;
    }
    for (const c in ebc) {
      if (Object.prototype.hasOwnProperty.call(ebc, c)) {
        // if (ev[e].XVAL % 5 === 0) {
        //   labels[e] = String(ev[e].XVAL) // do labels here
        // }
        cData[c] = ebc[c].YVAL;
      }
    }
    for (const a in acc) {
      if (Object.prototype.hasOwnProperty.call(acc, a)) {
        // if (ev[e].XVAL % 5 === 0) {
        //   labels[e] = String(ev[e].XVAL) // do labels here
        // }
        aData[a] = acc[a].YVAL;
      }
    }
    const markerlineColor = productId === 51 ?  "#A888B5": "#FF3364";
    const data = {
      labels: getLabels(projectedFinishWeek),
      bezierCurveTension: 0.4,
      datasets: [
        {
          label: "Earned Value",
          fill: false,
          strokeColor: markerlineColor,//purple
          backgroundColor: markerlineColor,
          borderWidth: 1,
          borderColor: markerlineColor,
          pointColor:markerlineColor,
          pointStrokeColor: markerlineColor,
          pointHighlightFill: markerlineColor,
          pointHighlightStroke: markerlineColor,
          data: eData
        },
        {
          label: "Planned Value",
          fill: false,
          strokeColor: "#41F35F",
          backgroundColor: "#41F35F",
          borderWidth: 1,
          borderColor: "#41F35F",
          pointColor: "#41F35F",
          pointStrokeColor: "#41F35F",
          pointHighlightFill: "#41F35F",
          pointHighlightStroke: "#41F35F",
          data: cData
        },
        {
          label: "Actual Cost",
          fill: false,
          strokeColor: "#41D5F3",
          backgroundColor: "#41D5F3",
          borderWidth: 1,
          borderColor: "#41D5F3",
          pointColor: "#41D5F3",
          pointStrokeColor: "#41D5F3",
          pointHighlightFill: "#41D5F3",
          pointHighlightStroke: "#41D5F3",
          data: aData
        }
      ]
    };

    return data;
  }
);

export const getPPIReport = createSelector(
  [getPPIData, getProjectedFinishWeek],
  (ppi, projectedFinishWeek) => {
    const datasets = [];
    const labels = [];
    const eData = [];
    //labels
    for (let i = 0; i <= (projectedFinishWeek + 1) * 5; i += 1) {
      labels[i] = `Week ${Math.round(i / 5)}`;
    }
    for (const e in ppi) {
      const datapoint = ppi[e];

      eData[datapoint.XVAL] = datapoint.YVAL;
    }
    const data = {
      labels: getLabels(projectedFinishWeek),
      bezierCurveTension: 0.4,
      datasets: [
        {
          label: "PPI",
          fill: false,
          strokeColor: "#7D33FF",
          backgroundColor: "#7D33FF",
          borderWidth: 1,
          borderColor: "#7D33FF",
          pointColor: "#7D33FF",
          pointStrokeColor: "#7D33FF",
          pointHighlightFill: "#7D33FF",
          pointHighlightStroke: "#7D33FF",
          data: eData
        }
      ]
    };

    return data;
  }
);
