import React from "react";
import styled from "styled-components";
import ResourceImage from "components/ResourceImage";

const ConditionActor = styled.div`
  width: 80px;
  height: 200px;
  display: block;
  overflow: hidden;
  margin-right: 2rem;

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 50%;
  }

  .actorTitle {
    text-align: center;
    font-weight: bold; 
    margin-top: 8px;
    font-size: 14px;
  }
  .actorTitleRole {
    text-align: center;
    margin-top: 2px;
    font-style: italic;
    font-size: 13px;
  }
`;

const PlayerDetails = (props) => {

  const getActorIconPng = () => {
    if (!props.player.worker) return "None.png";
    return `${props.player.worker.replace(/[\s.]/g, "")}.png`;
  };

  return (
    <ConditionActor>
      <ResourceImage alt={props.player.worker ?? "None"} png={getActorIconPng()} />
      {props.player.worker && (
        <div className="actorTitle">{props.player.worker}</div>
      )}
      {props.player.role &&
        props.player.worker !== props.player.role && (
          <div className="actorTitleRole">{props.player.role}</div>
        )}
      {props.player.title &&
        props.player.worker !== props.player.title && (
          <div className="actorTitleRole">{props.player.title}</div>
        )}
    </ConditionActor>
  );
};

export default PlayerDetails;
