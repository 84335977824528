import React from "react";

import { connect } from "react-redux";
import classnames from "classnames";
import ReferenceDocumentPDFLink from "components/ReferenceDocumentPDFLink";

import { fetchProjectData } from "redux/Project/thunks";
import {
  getSimplePolicyList,
  getSimpleProjectDocList,
  getSimpleResourcesList,
  getSimpleProfilesList,
  getWBSData,
  getWorkersBySkill,
  getWorkerSummary,
  getNonDeferredTaskSkillList
} from "redux/Reference/selectors";

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import {
  AbsenteeismBis,
  OrgChartBis,
  OrgChartBiss,
  RecognitionBis,
  ResignationBis,
  StakeholdersBis,
  TaskContainer,
  TrainingBis
} from "./Nog-components/index";

import {
  Absenteeism,
  BySkillContainer,
  CharterSimproject,
  CharterPM,
  CharterPacciss,
  Conferences,
  Meetings,
  OrgChart,
  Overtime,
  Quality,
  Recognition,
  Reserves,
  ResourceProfile,
  Resignation,
  Staffing,
  Stakeholders,
  SummaryContainer,
  Training,
  Vacations,
  WorkAssignments,
  WBSContainer
} from "./components";

import "./Reference.css";

const budget = require("./initialData/budgetList.json");
const tasks = require("./initialData/nonDeferredTaskSkillList.json");
const workers = require("./initialData/workerFixedList.json");
const bySkills = require("./initialData/workersBySkillList.json");
const summary = require("./initialData/workerSummary.json");
const profile = require("./initialData/profileList.json");

class Reference extends React.Component {
  constructor(props) {
    super(props);
    let selectedKey = "Charter";
    if (
      Object.hasOwnProperty.call(props, "selectedKey") &&
      props.selectedKey !== undefined &&
      props.selectedKey !== null
    ) {
      selectedKey = props.selectedKey;
    }

    this.state = {
      collapse: 1,
      selectedRefKey: selectedKey,
      profileList: this.props.runId === 0 ? profile : this.props.profileList,
      workerFixedList:
        this.props.runId === 0 ? workers : this.props.workerFixedList,
      budgetList: this.props.runId === 0 ? budget : this.props.budgetList,
      taskSkillList:
        this.props.runId === 0 ? tasks : this.props.nonDeferredTaskSkillList,
      workersBySkillList:
        this.props.runId === 0 ? bySkills : this.props.workersBySkillList,
      workerSummary: this.props.runId === 0 ? summary : this.props.workerSummary
      // charter index, see reference / selectors in routes
    };
  }

  handleRequestChange(event, index) {
    this.setState({
      selectedIndex: index
    });
  }

  //Render the correct components according to the productId (project)
  renderDocument = () => {
    let productId = this.props.productId;
    switch (this.state.selectedRefKey.toLowerCase()) {
      case "title":
        break;
      case "absenteeism":
        return productId === 50 || productId === 51 ? <AbsenteeismBis /> : <Absenteeism />;

      case "conferences":
        return <Conferences />;

      case "meetings":
        return <Meetings />;

      case "overtime":
        return <Overtime />;

      case "quality":
        return <Quality productId={productId} />;

      case "recognition":
        return productId === 50 || productId === 51 ? <RecognitionBis /> : <Recognition />;

      case "reserves":
        return <Reserves />;

      case "resignation":
        return productId === 50 || productId === 51 ? <ResignationBis /> : <Resignation />;

      case "staffing":
      case "resource":
        return <Staffing />;

      case "stakeholder":
        return productId === 50 || productId === 51 ? <StakeholdersBis /> : <Stakeholders />;

      case "training":
        return productId === 50 || productId === 51 ? <TrainingBis /> : <Training />;

      case "vacations":
        return <Vacations />;

      case "assignments":
        return <WorkAssignments />;

      case "charter":
        if (productId === 38) {
          return <CharterSimproject />;
        } else if (productId === 50 || productId === 51) {
          return <CharterPacciss />;
        } else {
          return <CharterPM />;
        }   

      case "orgchart":
        return productId === 50 ? <OrgChartBiss productId={productId} /> : 
        productId === 51 ? <OrgChartBis productId={productId} /> :
        <OrgChart />;
 
      case "wbs":
        return (
          <WBSContainer
            budgetList={this.state.budgetList}
            productId={this.props.productId}
          />
        );

      case "tasks":
        return <TaskContainer taskSkillList={this.state.taskSkillList} />;

      case "summary":
        return <SummaryContainer workerSummary={this.state.workerSummary} />;

      case "byskill":
        return (
          <BySkillContainer
            workersBySkillList={this.state.workersBySkillList}
          />
        );

      default:
        const worker = this.state.workerFixedList.filter(
          (wrkr) => wrkr.Name === this.state.selectedRefKey
        );
        if (worker.length > 0) {
          return <ResourceProfile worker={worker[0]} />;
        }
        return <span />;
    }
    return <span />;
  };

  changeSelectedReport = (rk, index) => {
    const {productId} = this.props;
    console.log("productId", productId)
    this.setState({
      selectedRefKey: rk
      // ,
    });

    const contentEl = document.querySelector(".row-Documentation_Content");
    // Scroll to the top when this route mounts
    contentEl.scrollIntoView();
  };

  toggleCollapse = (e) => {
    const { event } = e.target.dataset;
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event)
    });
  };

  // Function to change the h2 title with the correct project name according to the productId
  renderTitle = () => {
    let productId = this.props.productId;
    return productId === 50 || productId === 51 ? (
      <p> PACCISS Project - Company Reference Documents</p>
    ) : (
      <p> Uniworld Alliance Prototype Project - Company Reference Documents</p>
    );
  };

  render() {
    const i = 1;
    let iterKey = 1;
    return (
      <div id="wrapper-reference-doc">
        <Row>
          <Col xs="12">
            <h2>Project Reference Guide</h2>
          </Col>
          <Col md="6">
            {this.renderTitle()}
            <ReferenceDocumentPDFLink productId={this.props.productId} />
          </Col>
          <Col md="6">
            <p>
              If you can't find an answer here or in the Help Documentation,
              please <a href="mailto:support@simulationpl.com">contact us</a>{" "}
              for assistance.
            </p>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <Card className="ListGroup-Card" key={1}>
              <CardHeader
                onClick={this.toggleCollapse}
                data-event={1}
                className={classnames({
                  active: this.props.projDocList.some(
                    (row) => row.refKey === this.state.selectedRefKey
                  )
                })}
              >
                Project Documentation
              </CardHeader>
              <Collapse isOpen={this.state.collapse === 1}>
                <CardBody className="p-0">
                  <ListGroup>
                    {this.props.projDocList.map((row, index) => (
                      <ListGroupItem
                        tag="button"
                        className={classnames({
                          active: this.state.selectedRefKey === row.refKey
                        })}
                        key={(iterKey += 1)}
                        value={row.index}
                        onClick={this.changeSelectedReport.bind(
                          this,
                          row.refKey,
                          i
                        )}
                      >
                        {row.menuTitle}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="ListGroup-Card" key={2}>
              <CardHeader
                onClick={this.toggleCollapse}
                data-event={2}
                className={classnames({
                  active: this.props.policyList.some(
                    (row) => row.refKey === this.state.selectedRefKey
                  )
                })}
              >
                Company Policies
              </CardHeader>
              <Collapse isOpen={this.state.collapse === 2}>
                <CardBody className="p-0">
                  <ListGroup>
                    {this.props.policyList.map((row, index) => (
                      <ListGroupItem
                        tag="button"
                        className={classnames({
                          active: this.state.selectedRefKey === row.refKey
                        })}
                        key={(iterKey += 1)}
                        value={row.index}
                        onClick={this.changeSelectedReport.bind(
                          this,
                          row.refKey,
                          i
                        )}
                      >
                        {row.menuTitle}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="ListGroup-Card" key={3}>
              <CardHeader
                onClick={this.toggleCollapse}
                data-event={3}
                className={classnames({
                  active: this.props.resourceList.some(
                    (row) => row.refKey === this.state.selectedRefKey
                  )
                })}
              >
                Resources
              </CardHeader>
              <Collapse isOpen={this.state.collapse === 3}>
                <CardBody className="p-0">
                  <ListGroup>
                    {this.props.resourceList.map((row, index) => (
                      <ListGroupItem
                        tag="button"
                        className={classnames({
                          active: this.state.selectedRefKey === row.refKey
                        })}
                        key={(iterKey += 1)}
                        value={row.index}
                        onClick={this.changeSelectedReport.bind(
                          this,
                          row.refKey,
                          i
                        )}
                      >
                        {row.menuTitle}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Collapse>
            </Card>
            <Card className="ListGroup-Card" key={4}>
              <CardHeader
                onClick={this.toggleCollapse}
                data-event={4}
                className={classnames({
                  active: this.state.profileList.some(
                    (row) => row.refKey === this.state.selectedRefKey
                  )
                })}
              >
                Individual Resource Profiles
              </CardHeader>
              <Collapse isOpen={this.state.collapse === 4}>
                <CardBody className="p-0">
                  <ListGroup>
                    {this.state.profileList.map((row, index) => (
                      <ListGroupItem
                        tag="button"
                        className={classnames({
                          active: this.state.selectedRefKey === row.refKey
                        })}
                        key={(iterKey += 1)}
                        value={row.index}
                        onClick={this.changeSelectedReport.bind(
                          this,
                          row.refKey,
                          i
                        )}
                      >
                        {row.menuTitle}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </CardBody>
              </Collapse>
            </Card>
          </Col>
          <Col
            md="8"
            className="row-Documentation_Content"
            key={(iterKey += 1)}
          >
            {this.renderDocument()}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Reference;

const mapStateToProps = (state) => ({
  workers: state.project.workers,
  runId: state.project.runId,
  currentProjectWeek: state.project.currentProjectWeek,
  taskSkillList: state.project.taskSkillList,
  workerFixedList: state.project.workerFixedList,
  budgetList: getWBSData(state),
  policyList: getSimplePolicyList(state),
  projDocList: getSimpleProjectDocList(state),
  resourceList: getSimpleResourcesList(state),
  profileList: getSimpleProfilesList(state),
  workersBySkillList: getWorkersBySkill(state),
  workerSummary: getWorkerSummary(state),
  nonDeferredTaskSkillList: getNonDeferredTaskSkillList(state),
  productId: state.auth.productId
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectData: (runId) => dispatch(fetchProjectData(runId))
});

export const ReferenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Reference);
