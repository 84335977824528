import React from 'react';
import ResourceImage from 'components/ResourceImage';
import PlayerDetail from "./PlayerDetails";
import styled from "styled-components";

const PlayerDetailContainer = styled.div`
  margin-right: 0px !important;
  width: 40px !important;
`;

const Release = props => {
	// if there was no new staffing activity, worker will be none, don't need
	// image or worker then, just the status
	var iterKey = 1;

	// eslint-disable-next-line no-return-assign
	return (
		<div className='report-Item'>
			<PlayerDetailContainer className="report-Item_Icon">
				<PlayerDetail player={props.properties} />
			</PlayerDetailContainer>
			<div className='report-Item_Content'>
				was released from the project.
			</div>
		</div>
	);
};

export default Release
